import {RouterFilter} from "@/router/filter/RouterFilter";
import {NavigationGuardNext, RouteLocationNormalized} from "vue-router";
import {URLAccessFilter} from "@/router/filter/URLAccessFilter.js";

class FilterChain {

    private static readonly FORBIDDEN_TO_MODIFY_URL:RouterFilter = new URLAccessFilter()

    static doFilter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void {

        FilterChain.FORBIDDEN_TO_MODIFY_URL.filter(to, from, next)
    }
}

export {FilterChain}