import {createRouter, createWebHistory} from 'vue-router'
import {FilterChain} from "@/router/filter/FilterChain";
import {vueRouterConfig} from "@/router/RouterConfig.js";

/**
 * 动态加载vue文件到vue-router
 */
// require.context('@/views',true, /\.vue$/).keys().forEach( async value => {
//
//   let path = value.substring(value.indexOf('/'), value.lastIndexOf('.'))
//   const componentLocation = value.substring(value.indexOf('.') + 1, value.lastIndexOf('.'))
//   let pathArray = path.split("/")
//
//   pathArray = pathArray.filter((value, index, array)=>{
//     return value.trim() !== '';
//   })
//   console.log(componentLocation)
//   console.log(pathArray)
// })


// console.log(routerArray)
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: vueRouterConfig,
})

router.beforeEach((to, from, next)=>{
  FilterChain.doFilter(to, from, next)
})
router.afterEach(() => {
  history.pushState(null, '', document.URL)
  setTimeout(() => {
    if(document.querySelector('.ant-layout-content')) {
      document.querySelector('.ant-layout-content')?.scrollTo(0, 0)
    }
  }, 1000)
})

export default router

