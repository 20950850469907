/** 模板 */
<template lang="html">
      <router-view />
</template>

<style>
/* 优化滚动条 */
::-webkit-scrollbar {
      width: 5px;
      height: 10px;
      background-color: #f0f2f5;
}

::-webkit-scrollbar-thumb {
      background-color: rgb(171, 171, 171);
}
</style>