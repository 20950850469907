import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue'

import 'element-plus/theme-chalk/index.css';

import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn'
import "video.js/dist/video-js.css"; // 引入video.js的css
import hls from "videojs-contrib-hls"; // 播放hls流需要的插件
import Vue from "vue";

const app = createApp(App)
app.use(hls);
app.use(router)
app.use(Antd)
app.use(ElementPlus, { locale })
app.mount('#app')
